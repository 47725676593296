<template>
  <!-- 店铺管理 -->
  <div class="container">
    <div class="nav" >
       <div class="dataOper">
        <!-- 查询 -->
        <div>
          <div class="search_box">
            <div class="search_main">
              <div class="status" style="width: 356px;">
                <div>入驻时间</div>
                  <el-date-picker v-model="datatime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd">
                  </el-date-picker>
            </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div style="margin-left: -20px;">店铺状态</div>
                <div>
                  <el-select style="color:red"   v-model="queryinfo.status">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="(item,index) in options" :key="index" :label="item" :value="index"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_main" style="margin-left: -15px;">
              <div class="status">
                <div>手机号</div>
                <div>
                  <el-input style="width: 260px" v-model="queryinfo.phone" placeholder="请输入内容"></el-input>
                </div>
              </div>
            </div>
            <br/>
            <div class="search_main">
              <div class="status">
                <div>店铺名称</div>
                <div>
                  <el-input placeholder="请输入内容" v-model="queryinfo.name" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>BD姓名</div>
                <div>
                  <el-input v-model="queryinfo.invokeName" placeholder="请输入内容" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status1" style="display: flex;">
                <div class="demandBtn" @click="search">
                  <img src="../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../assets/images/reset.png" alt="" />重置
                </div>
                <div class="addBtn" @click="$router.push('/store/add')">
                  <img src="../../assets/images/add.png" style="margin-right: 5px;width: 13px;"/>添加店铺
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查询 -->
      </div>
    </div>
    <div style="height: 17px"></div>
    <div class="body">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
                  style="width: 100%;font-family: PingFangRoutine;" :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px'}">
          <el-table-column prop="name" label="店铺名称" >
          </el-table-column>
          <el-table-column prop="contact" label="店铺联系人" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系人手机号" align="center">
          </el-table-column>
          <el-table-column prop="invokeName" label="BD姓名" align="center" width="100">
          </el-table-column>
          <el-table-column prop="invokeCode" label="BD邀请码" align="center"  width="100">
          </el-table-column>
          <el-table-column prop="createTime" label="入驻时间" align="center" width="170"/>
          <el-table-column prop="periodEndtime" label="到期时间" align="center"  width="170">
          </el-table-column>
          <el-table-column  label="二级商户ID" align="center">
            <template #default="scope">
              <div>{{ scope.row.subMchid === null ? "---" : scope.row.subMchid }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="weight" label="权重" align="center" width="100%">
            <template #default="scope">
              <div>
                <el-input v-model="scope.row.weight" @blur="blurWeight(scope.row)"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center" width="80">
            <template v-slot:default="scope">
              <div v-for="(item, index) in options" :key="item.id">
                <div>{{ scope.row.status == index&&scope.row.status !=-1 ? item : scope.row.status == index&&scope.row.status == -1?'待完善':'' }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="storeOver(scope.row)" v-if="scope.row.status >= 3 ? true : false">
                  <img style="width: 18px;height: 18px;" src="../../assets/images/edit.png"/>
                </div>
                <div class="edit" @click="storeEdit(scope.row)" v-if="scope.row.status == -1 ? true : false">
                    <img style="width: 18px;height: 18px;" src="../../assets/images/edit.png"/>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </div>
    <div style="height: 18px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import {
  storeList,
  storeOptionStatus,
  storeChangeWeight,
  storeQueryMerchant,
} from "@/api/store";
export default {
  data() {
    return {
      queryinfo: {
        currPage:'', // 分页
        startTime:"" , //入住时间
        status: "", // 店铺状态
        phone:"", //手机号
        name:"", // 店铺名称
        invokeName:"" ,//bD姓名
        endTime: "", //搜索结束时间
      },
      datatime:[],
      tableData: [],
      total: null,
      dialogFormVisible: false,
      options: [],  //店铺状态选项
    };
  },
  activated() {
    this.list();
  },
  methods: {
    //订单列表
    list() {
      storeList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
      //获取店铺状态
      storeOptionStatus().then((res) => {
        console.log();
        this.options = res.data;
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px';";
      }
    },
    //搜索
    search() {
      if (typeof this.datatime !== 'string' && this.datatime.length !== 0) {
        let time1 = this.datatime[0]
        let newtime1 = time1.getTime()
        let item1 = new Date(newtime1)

        let time2 = this.datatime[1]
        let newtime2 = time2.getTime()
        let item2 = new Date(newtime2)

        let y1 = item1.getFullYear()
        let m1 = item1.getMonth() + 1
        let d1 = item1.getDate()

        let y2 = item2.getFullYear()
        let m2 = item2.getMonth() + 1
        let d2 = item2.getDate()
        let timeStr1 = y1 + '-' + m1 + '-' + d1 + ' ' + '0:00:00'
        let tiemStr2 = y2 + '-' + m2 + '-' + d2 + ' ' + '23:59:59'
        this.queryinfo.startTime = timeStr1,
        this.queryinfo.endTime = tiemStr2
      }
       this.queryinfo.currPage = ""
        this.total = 0
      this.list()
    },
    // 重置
    reset() {
    this.total=0
    this.queryinfo.startTime="",
    this.queryinfo.status="",
    this.queryinfo.phone="",
    this.queryinfo.invokeName="",
    this.queryinfo.name="",
    this.queryinfo.currPage="",
    this.datatime=[]
    this.list()
    },

    blurWeight(row) {
      storeChangeWeight({ id: row.id, weight: row.weight }).then(() => {});
    },

    //查看
    storeOver(row) {
      this.$router.push({ path: "/store/detail", query: { id: row.id} });
    },
    // 状态
    storeStatus(row) {
      storeQueryMerchant({ memberId: row.memberId }).then((res) => {
        this.tableData.forEach((item) => {
          if (item.id === row.id) {
            item.status = 1;
            // this.queryinfo.status = item.status;
          }
        });
      });
    },
    //编辑
    storeEdit(row) {
      this.$router.push({ path: "/store/edit", query: { id: row.id } });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/common.less";
@import "../../font-style/font.css";
.head{
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  box-sizing: border-box;
}
.search_main {
    display: inline-block;
}

.status>div:nth-child(1) {
  width: 57px;
  text-align: right;
}
.el-main{
    background: #f3f3f4;
    color: #333;
    width: 100%;
    height: 100vh !important;
}
</style>
